/*input*/
.address-common_input_wrapper {
  padding: 0;
  width: 100%;
  margin-bottom: 15px;
}
@media all and (min-width: 992px) {
  .address-common_input_wrapper {
    width: 45%;
  }
}

.address-common_input {
  position: relative;
  width: 100%;
  border: 2px solid lightgray;
  border-radius: 3px 3px 3px 3px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.address-common_input > input {
  width: 100%;
  padding-left: 10px;
  opacity: 0;
  border: none;
  border-radius: 3px 3px 3px 3px;
}
.address-common_input > input:focus {
  outline: none;
}
.address-common_input.focus-input {
  border-color: #009250;
}
.address-common_input-title {
  position: absolute;
  top: 5%;
  left: 20px;
  background-color: white;
  translate: 1s;
  color: #42494d;
  font-size: 14px;
  font-weight: 500;
  color: lightgray;
  transition: 0.5s;
}
.address-common_input.focus-input .address-common_input-title {
  top: -50%;
  color: #42494d;
}
.address-common_input.focus-input > input {
  opacity: 1;
}
.address-common_input.validate-input > input {
  opacity: 1;
}
.address-common_input.validate-input .address-common_input-title {
  top: -50%;
  color: #42494d;
}

.add-cpn_child_column {
  width: 100%;
}
@media all and (min-width: 992px) {
  .add-cpn_child_column {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media all and (min-width: 992px) {
  .address-common_input_wrapper.add {
    width: 90%;
  }
}

.guide {
  top: 8px;
  right: 10px;
}

.qr-scan {
  top: 8px;
  right: 40px;
}

.info-guide {
  color: #009250;
}

.qr-scan-icon {
  color: #333dcb;
}

.tooltiptext {
  visibility: hidden;
  width: 360px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  top: 20px;
  right: inherit;
  z-index: 1;
  /* Position the tooltip */
  position: absolute;
}

.i-tooltip:hover .tooltiptext {
  visibility: visible;
}
