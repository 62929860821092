.nav-list-cpn {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  overflow: auto;
  width: 100vw;
}
@media all and (min-width: 992px) {
  .nav-list-cpn {
    height: calc(50vw / 9 / 12 * 11 * 2.7);
  }
}
