.nav-item-child-img {
  min-height: 100px;
  min-width: 100px;
  width: 10vw;
  height: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0dfdf;
  cursor: pointer;
}
.nav-item-child-img:hover {
  background-color: #f37e1f;
}

.nav-item-child-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 89% 11% 91% 9% / 6% 92% 8% 94%;
}


.nav-item-ctn {
  padding: 10px;
}