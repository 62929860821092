.receiver-info-wrapper {
  width: 300px;
  padding: 5px;
  background-color: white;
  box-shadow: lightgray 0px 0px 2px 1px;
  border: 1px solid lightgray;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.receiver-info-wrapper.active {
  border: 1px solid lightseagreen;
}

.receiver-info-wrapper:hover {
  background-color: lightgray;
}

.receiver-info-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: gray;
  padding: 0 5px;
}
.receiver-info-row > i {
  vertical-align: middle;
  margin: auto 0;
  width: 30px;
}
.receiver-info-row > label {
  width: 270px;
  vertical-align: middle;
  margin: auto 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
