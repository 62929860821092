.address-component_child {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 5px;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  margin-bottom: 10px;
  padding: 10px;
}
.dashboard_button_child.address {
  width: fit-content;
}

/*address*/
.address-wrapper {
  display: flex;
  flex-direction: column;
}

/*button*/
.dashboard_button_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.dashboard_button_child {
  background-color: #0090ec;
  padding: 5px 15px;
  cursor: pointer;
  color: white;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: 0.25s;
  margin: 5px 0px;
}
@media all and (max-width: 991px) {
  .dashboard_button_child {
    padding: 5px;
    margin: 2px;
  }
}
.dashboard_button_child:focus {
  outline: none;
  border: none;
}
.dashboard_button_child:active {
  transform: translateY(4px);
}

.add-address_btn_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dashboard_button_child.address-add {
  width: fit-content;
}
.receiver-info_component_container {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  margin-bottom: 10px;
}
.receiver-info_component_container.even {
  background-color: #f5f5f5;
}
.receiver-info_component_container:hover {
  background-color: lightgray;
}
.re-info-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
}
.re-info_cpn_btn {
  margin: 1px !important;
  font-size: 14px;
  background-color: #ff9300 !important;
}

.del_btn {
  margin: 1px !important;
  font-size: 14px;
  background-color: rgb(199, 54, 54) !important;
}
