.comment-container {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.comment-container > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
/*avatar*/
.comment-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
@media all and (max-width: 991px) {
  .comment-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.comment-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

/*value*/
.comment-value {
  width: calc(100% - 60px);
  background-color: #f5f5f5;
  min-height: 52px;
  border-radius: 15px;
  padding: 3px 15px;
}
.comment-value > div:nth-child(1) {
  font-weight: 500;
}
.comment-value > div:nth-child(2) {
  flex-wrap: wrap;
  word-wrap: break-word;
}
@media all and (max-width: 991px) {
  .comment-value {
    width: calc(100% - 60px);
    background-color: #f5f5f5;
    min-height: 40px;
    border-radius: 10px !important;
    padding: 2px 10px;
  }
  .comment-value > div:nth-child(1) {
    font-size: 14px !important;
    line-height: 16px;
  }
  .comment-value > div:nth-child(2) {
    font-size: 13px !important;
    flex-wrap: wrap;
    word-wrap: break-word;
  }
}

/*repsponse btn*/
.comment-repsponse-btn {
  font-weight: 500;
  width: fit-content;
  cursor: pointer;
  margin-left: 75px;
}
@media all and (max-width: 991px) {
  .comment-repsponse-btn {
    font-size: 13px;
    margin-left: 60px;
  }
}
.comment-repsponse-btn:hover,
.comment-repsponse-btn:active {
  color: #2b80dd;
}

/*repsponse value*/
.comment-repsponse-value {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  padding: -10px;
  padding-left: 55px;
  width: 100%;
}
.comment-repsponse-value textarea {
  padding: 2px 15px;
  background-color: #f5f5f5;
  border-radius: 15px;
  resize: none;
}

/*count*/
.comment-repsponse-count {
  margin-left: 75px;
  cursor: pointer;
  width: fit-content;
}
@media all and (max-width: 991px) {
  .comment-repsponse-count {
    margin-left: 60px;
  }
}
.comment-repsponse-count:hover,
.comment-repsponse-count:active {
  color: #2b80dd;
}

/*child*/
.comment-repsponse-child {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding-left: 55px;
  width: 100%;
}
