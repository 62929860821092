
.body-component-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 35px;
}
.body-component-title {
  font-weight: 500;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-bottom: 4px solid #f37e1f;
  color: #f37e1f;
  background-color: white;
  padding: 0 20px;
}

@media all and (min-width: 992px) {
  .body-component-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 50px;
  }
  .body-component-title {
    font-weight: 500;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid #f37e1f;
    color: #f37e1f;
  }
}

.category-list-cpn {
  height: 270px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  overflow-x: auto;
  border-top: 1px solid lightgray;
}
@media all and (min-width: 992px) {
  .category-list-cpn {
    height: calc(100vw / 9 / 12 * 11 * 2.7);
  }
}

.category-item-ctn {
  height: 125px;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding-top: 5px;
  padding: 5px;
}
.category-item-ctn.focus {
  background-color: #ee6246;
  border-radius: 2px;
}
.category-item-ctn:hover {
  background-color: #f99784;
}
.category-item-img {
  height: 75px;
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-radius: 50%;
  overflow: hidden;
}
.category-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.category-item-name {
  margin-top: 5px;
  text-align: center;
  font-size: 70%;
  width: 100%;
}
@media all and (min-width: 992px) {
  .category-item-ctn {
    height: calc(125vw / 9 / 12.16 * 11);
    width: calc(100vw / 9 / 12.16 * 11);
    padding-top: 10px;
  }
  .category-item-img {
    height: calc(75vw / 9 / 12 * 11);
    width: calc(75vw / 9 / 12 * 11);
  }
  .category-item-name {
    font-size: 100%;
  }
}
