.banner-home-container {
  display: flex;
  flex-direction: row;
}
.banner-home-left-container {
  display: flex;
  flex: 2;
}
.banner-home-right-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.body-list-cpn-tool {
  display: flex;
  flex: 3;
  height: 45px;
  background-color: white;
  margin-left: 5px;
}
@media all and (max-width: 991px) {
  .body-list-cpn-tool {
    display: flex;
    flex: 1;
    height: 35px;
    background-color: white;
    margin-left: 5px;
  }
  .body-list-cpn-header.small .body-list-cpn-tool {
    display: none;
  }
}
