.featured-news-container {
  margin: 20px;
}

.featured-news-child {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media screen and (min-width: 1600px) {
  .feature-new-resize {
    width: calc(((100% - 20px) / 3) - 20px);
  }

  .first-new-content {
    width: calc(100% - 280px);
  }
}

@media screen and (min-width: 800px) and (max-width: 1599px) {
  .feature-new-resize {
    width: calc(((100% - 20px) / 2) - 20px);
  }
  .first-new-content {
    width: calc(100% - 280px);
  }
}

@media screen and (max-width: 799px) {
  .feature-new-resize {
    width: calc(100% - 40px);
  }
  .first-new-content {
    width: 100%;
    margin-top: 10px;
  }
}

.feature-new-item {
  max-width: 100vw;
  margin: 10px auto;
  background-color: rgb(238, 234, 234);
  box-shadow: rgb(223, 222, 222) 0px 0px 5px 1px;
  display: flex;
  flex-direction: row;
}

.featured-news-item-title {
  margin-left: 10px;
}

.feature-new-avatar {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.first-new-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px;
  justify-content: center;
}

.first-new-avatar {
  width: 280px;
  height: 210px;
  object-fit: cover;
  max-width: 90vw;
}

.first-new-content {
  padding-left: 20px;
  overflow: hidden;
}

.first-news-item-title {
  font-size: larger;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 3.2ex;
  max-height: 6.4ex;
}

.first-news-item-des {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 3.2ex;
  max-height: 19.2ex;
}

.more-new {
  cursor: pointer;
}

.more-new:hover {
  color: gray;
}

.home-news {
  margin-top: 20px;
}

.featured-news-adds {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}