.banner-image-ad {
  width: 100%;
  flex-shrink: 0;
  height: calc(100vw / 12 * 11 / 851 * 315 * 1 / 3);
  object-fit: cover;
}
@media all and (max-width: 991px) {
  .banner-image-ad {
    height: calc(100vw / 12 * 11 / 851 * 315 / 2);
  }
}
