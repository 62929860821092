.od-prd-child-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px;
  font-weight: 400;
  font-size: 17px;
}
.od-prd-child_avatar {
  width: 125px;
  height: 125px;
  border: 1px dotted lightgray;
}
.od-prd-child_avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.od-prd-child_des {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100px;
  justify-content: space-between;
  padding: 5px;
}
.od-prd-child-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.od-prd-child-price {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: red;
  font-size: 1.35vw;
  font-weight: 500;
  line-height: 30px;
  font-style: italic;
  text-shadow: 2px 2px 2px lightgray, -2px -2px 2px lightgray, 2px -2px 2px lightgray,
    -2px 2px 2px lightgray;
  margin-right: 5px;
}

@media all and (max-width: 991px) {
  .od-prd-child_avatar {
    width: 75px;
    height: 75px;
  }
  .od-prd-child-container {
    font-size: 13px;
  }
  .od-prd-child-price {
    font-size: 16px;
  }
}
