.order-requirement-wrapper {
  cursor: pointer;
  margin: 10px 0px;
  background-color: #f3f3f3;
  padding: 0px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgray;
}
.od-header {
  padding: 10px;
  border-bottom: 1px dotted lightgray;
}
.od-header-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.od-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}
