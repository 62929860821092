.company-image-container {
  background-color: white;
}

.company-image-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.company-image-child {
  margin: 10px;
  overflow: hidden;
}

@media screen and (min-width: 1240px) {
  .company-image-child {
    width: calc((100vw - 40px) / 3 - 22px);
  }
}

@media screen and (min-width: 700px) and (max-width: 1239px) {
  .company-image-child {
    width: calc((100vw - 40px) / 2 - 22px);
  }
}

@media screen and (max-width: 699px) {
  .company-image-child {
    width: calc(100vw - 62px);
  }
}

.company-image-child img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid lightgray;
  cursor: pointer;
}

.company-image-title {
  font-size: large;
  font-weight: 600;
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid lightgray;
}
