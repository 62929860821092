.consulation-icon {
  position: fixed;
  width: 60px;
  height: 60px;
  right: 24px;
  bottom: 24px;
  background-color: #f37e1f;
  border-radius: 50%;
  z-index: 300;
  display: flex;
  align-items: center;
}

.consulation-icon i {
  font-size: 38px;
  margin: auto;
  color: white;
}

.consulation-tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  top: -20px;
  right: 60px;
  z-index: 1;
  position: absolute;
  border: 1px solid rgb(231, 228, 228);
}

.consulation-icon:hover .consulation-tooltiptext {
  visibility: visible;
}

.jvs-chat-container {
  position: fixed;
  width: 48px;
  height: 48px;
  right: 24px;
  bottom: 96px;
  background-color: #f37e1fa8;
  border-radius: 50%;
  z-index: 300;
  display: flex;
  align-items: center;
}
.jvs-chat-container > img {
  width: 32px;
  margin: auto;
}

.zalo-chat-container {
  display: flex;
  align-items: center;
  position: fixed;
  width: 48px;
  height: 48px;
  right: 24px;
  bottom: 40px;
  background-color: #135aab8c;
  border-radius: 50%;
  z-index: 300;
}
.zalo-chat-container > div {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-image: url(/src/assets/img/zalo_2021.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 65%;
  margin: auto;
}
/* 
.facebook-chat-container {
  display: flex;
  align-items: center;
  position: fixed;
  width: 60px;
  height: 60px;
  right: 24px;
  bottom: 24px;
  background-color: #0a7cff;
  border-radius: 50%;
  z-index: 300;
}

.facebook-chat-container svg {
  margin: auto;
} */
