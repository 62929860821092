
/*main*/
.body-list-main-mobile-container {
    width: 100%;
    background-color: #f5f5f5;
    flex-direction: column;
    height: fit-content;
    overflow: auto;
    overflow-y: scroll;
    max-height: 80vh;
  }
  